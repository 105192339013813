import {useState, useEffect, useRef, MutableRefObject} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faChevronCircleRight, faCircle} from "@fortawesome/free-solid-svg-icons";
import {faDotCircle} from "@fortawesome/free-regular-svg-icons";

export type TestimonialControls = {
   testimonialsRef: MutableRefObject<HTMLDivElement>
}

export default function TestimonialControls(props: TestimonialControls) {
   const [testimonialIndex, setTestimonialIndex] = useState(0);
   const [testimonials, setTestimonials] = useState([]);

   const testimonialTimeout = useRef<number>();

   useEffect(() => {
      setTestimonials(prev => {
         let allTestimonials = prev;
         let tlist = props.testimonialsRef.current.querySelectorAll(".testimonial");
         for (let i = 0; i < tlist.length; i++) {
            allTestimonials.push(i);
         }
         return allTestimonials;
      });
      run();
      return () => clearTimeout(testimonialTimeout.current);
   }, [])

   useEffect(() => {
      let i = testimonialIndex || 0;
      let tbox = props.testimonialsRef.current;
      let tlist = props.testimonialsRef.current.querySelectorAll<HTMLDivElement>(".testimonial");
      //$(".testimonials").css("background", "#016699");
      tbox.style.left = `${-i * 100}%`;
      for (let j = 0; j < tlist.length; j++) {
         tlist[j].style.opacity = j === i ? "1" : "0";
      }
      testimonialTimeout.current && clearTimeout(testimonialTimeout.current);
      run();
   }, [testimonialIndex])

   const run = () => {
      testimonialTimeout.current = window.setTimeout(() => {
         nextTestimonial();
         run();
      }, 5000);
   };

   const testimonialSelection = i => {
      return i === testimonialIndex ? <FontAwesomeIcon icon={faDotCircle}/> : <FontAwesomeIcon icon={faCircle}/>
   };

   const selectTestimonial = (evt, i) => {
      evt.preventDefault();
      setTestimonialIndex(i);
   };

   const prevTestimonial = (evt?) => {
      evt && evt.preventDefault();
      setTestimonialIndex(prev => {
         const i = prev - 1;
         const tlist = props.testimonialsRef.current.querySelectorAll(".testimonial");
         return i < 0 ? tlist.length - 1 : i;
      })
   };

   const nextTestimonial = (evt?) => {
      evt && evt.preventDefault();
      setTestimonialIndex(prev => {
         const i = prev + 1;
         const tlist = props.testimonialsRef.current.querySelectorAll(".testimonial")
         return i >= tlist.length ? 0 : i;
      })
   };

   return <div className="testimonial-controls">
      <a href="#" onClick={prevTestimonial}>
         <FontAwesomeIcon icon={faChevronCircleLeft}/>
      </a>
      {testimonials.map((testimonial, i) => <span key={i}>
         <a href="#"
            className="testimonial-item"
            onClick={evt => selectTestimonial(evt, testimonial)}>{testimonialSelection(testimonial)}</a>
      </span>)}
      <a href="#" onClick={nextTestimonial}>
         <FontAwesomeIcon icon={faChevronCircleRight}/>
      </a>
   </div>
}
