import * as React from "react";

export type ImagicianSourceProps = {
   galleryName: string,
   imageName: string,
   types?: string[],
   dimensions?: string[]
}

export default function ImagicianSources(props: ImagicianSourceProps) {
   const types = props.types || ["webp", "jpg"];
   const dimensions = props.dimensions || ["480", "1080"];

   function srcSet(type) {
      return dimensions.map(dimension => {
         let entry = `/amoge/imagician/galleries/${props.galleryName}/${dimension}.${type}/images${props.imageName}`;
         if (!dimension.startsWith("x")) {
            entry += ` ${dimension}w`;
         }
         return entry;
      }).join(",");
   }

   const src = type => {
      const lastDimension = dimensions[dimensions.length - 1];
      return `/amoge/imagician/galleries/${props.galleryName}/${lastDimension}.${type}/images${props.imageName}`
   }

   return <>
      {types.map(type => <source key={type} type={`image/${type}`} srcSet={srcSet(type)} src={src(type)}/>)}
   </>
}