import {useEffect, useRef} from "react";
import AmogeServer from "./AmogeServer";

declare global {
   const grecaptcha: {
      render: (elem: JSX.Element, config: { sitekey: string, callback: GrecaptchaCallback }) => void,
      reset: () => void
   }
}

export type GrecaptchaCallback = (response: any) => void;

export type GoogleRecaptchaProps = {
   name: string,
   recaptchaId: string,
   callback: GrecaptchaCallback
}

export default function GoogleRecaptcha({name, recaptchaId, callback}: GoogleRecaptchaProps) {
   const elemRef = useRef(undefined);


   useEffect(() => {
      AmogeServer("/amoge/api/client-properties").then(clientProperties => {
         const readyCallbackName = `googleRecaptchaReady_${name}`;
         const sitekey = clientProperties[recaptchaId];
         window[readyCallbackName] = () => {
            grecaptcha.render(elemRef.current, {
               "sitekey": sitekey,
               "callback": response => callback(response),
            });
         };

         const script = document.createElement('script');
         script.src = `https://www.google.com/recaptcha/api.js?onload=${readyCallbackName}&render=explicit`;
         script.async = true;
         document.body.appendChild(script);
      });
   }, [])

   return <div>
      <div ref={elemRef}/>
   </div>
}