import {useRef} from "react";
import NavButton from "./nav/NavButton";
import NavMenuBar from "./nav/NavMenuBar";
import DynamicLogo from "./dynamic-logo/DynamicLogo";
import HeadlineView from "./headline/HeadlineView";
import Slideshow from "@unclejust/amoge-client/src/components/Slideshow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContactInfo from "./contact/ContactInfo";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import * as dayjs from "dayjs";
import TestimonialsList from "./testimonials/TestimonialsList";
import TestimonialControls from "./testimonials/Testimonials";
import EnquiryForm from "./contact/EnquiryForm";
import "./Home.css";
import "./headline/HeadlineView.css";
import Popup from "@unclejust/amoge-client/src/components/Popup";
import {SessionProvider} from "./session/DmcSession";

export default function Home() {

   const testimonialsRef = useRef();

   return <>
      <Popup/>
      <SessionProvider>
         <header className="index">
            <NavButton/>
            <DynamicLogo narrowClass="index"/>
            <NavMenuBar/>
            <HeadlineView/>
            <div className="banner-image">
               <Slideshow id="index" galleryName="main"/>
            </div>
         </header>
         <main className="index">
            <div className="business-description">
               Professional Aesthetic Treatments
            </div>
            <div className="index-row-container treatment-info">
               <div className="index-row width-control">
                  <div className="info-box">
                     <h1><a href="/treatment-info.html#antiWrinkle">Anti-Wrinkle Injection Treatments</a>
                     </h1>
                     <div className="treatment-content">
                        <a href="/treatment-info.html#antiWrinkle">
                           <div className="treatment-img anti-wrinkle-img">
                           </div>
                        </a>
                        <div>Eradicate or smooth fine lines and wrinkles. Relax muscles to sculpt and define</div>
                     </div>
                  </div>
                  <div className="info-box">
                     <h1><a href="/treatment-info.html#dermalFiller">Dermal Filler Treatments</a></h1>
                     <div className="treatment-content">
                        <a href="/treatment-info.html#dermalFiller">
                           <div className="treatment-img dermal-filler-img">
                           </div>
                        </a>
                        <div>
                           Adds shape definition and volume, effects can be subtle or dramatic. Smooth or eradicate deep
                           wrinkles and skin creases
                        </div>
                     </div>
                  </div>
                  <div className="info-box">
                     <h1><a href="/treatment-info.html#excessiveSweating">Excessive Sweating Treatment</a></h1>
                     <div className="treatment-content">
                        <a href="/treatment-info.html#excessiveSweating">
                           <div className="treatment-img hyperhydrosis-img">
                           </div>
                        </a>
                        <div>Stops sweating! Incredibly effective treatment for excess sweating</div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="index-row-container testimonials">
               <div className="index-row width-control">
                  <div className="info-box" ref={testimonialsRef}>
                     <TestimonialsList/>
                  </div>
               </div>
               <div className="index-row width-control">
                  <TestimonialControls testimonialsRef={testimonialsRef}/>
               </div>
            </div>
            <div className="index-row-container contact-us">
               <div className="index-row width-control">
                  <div className="info-box for-more-info">
                     <h1>Contact Us</h1>
                     <div className="info-box-columns">
                        <div>
                           <ContactInfo/>
                        </div>
                        <div className="enquiry-form-box">
                           <EnquiryForm/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
         <footer className="index">
            <div className="width-control">
               <div className="social-media">
                  <span className="dmc">Dr. Mary's Clinic</span>
                  <a href="https://www.facebook.com/drmarysclinic">
                     <FontAwesomeIcon icon={faFacebook} style={{padding: "0 5px"}}/>
                  </a>
                  <a href="https://www.instagram.com/drmarysclinic">
                     <FontAwesomeIcon icon={faInstagram} style={{padding: "0 5px"}}/>
                  </a>
               </div>
               <div>
                  <a href="/treatment-info.html">Treatments</a> |&nbsp;
                  <a href="/pricing.html">Pricing</a> |&nbsp;
                  <a href="/about-us.html">About Us</a> |&nbsp;
                  <a href="/contact-us.html">Contact Us</a>
               </div>
               <div><a href="/privacy-policy.html">Privacy Policy &amp; Cookies</a></div>
               <div>Copyright &copy; {dayjs().year()} Uncle Just</div>
            </div>
         </footer>
      </SessionProvider>
   </>
}