import {useState} from "react";
import AmogeServer from "@unclejust/amoge-client/src/utils/AmogeServer";
import {ActionLinkGroup} from "@unclejust/amoge-client/src/components/ActionLinkGroup";
import ActionLink from "@unclejust/amoge-client/src/components/ActionLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import GoogleRecaptcha from "@unclejust/amoge-client/src/utils/GoogleRecaptcha";

function useEnquiryForm() {
   const [name, setName] = useState("");
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState("");
   const [enquiry, setEnquiry] = useState("");
   const [inProgress, setInProgress] = useState<boolean>();
   const [invalid, setInvalid] = useState({});
   const [resultMsg, setResultMsg] = useState("");
   const [resultCss, setResultCss] = useState("result");
   const [recaptcha, setRecaptcha] = useState("");

   const reset = () => {
      setName("");
      setEmail("");
      setPhone("");
      setEnquiry("");
      setResultCss("result");
      setResultMsg("");
      setInProgress(false);
      grecaptcha && grecaptcha.reset();
   };

   const sendEnquiry = () => {
      const validateResult = validate();
      setInvalid(validateResult);
      if (validateResult) {
         return;
      }

      setInProgress(true);
      AmogeServer("/api/enquiries", {
         method: "POST",
         processData: false,
         data: JSON.stringify({name, phone, email, enquiry, recaptcha})
      }, false).then(response => {
         console.info(response);
         setResultMsg("Enquiry sent successfully");
         setResultCss("result success");
         window.setTimeout(() => reset(), 3000);
      }, errorResponse => {
         console.error(errorResponse);
         setResultMsg("Enquiry not sent! Please try again later");
         setResultCss("result fail");
         window.setTimeout(() => reset(), 3000);
      })
   };

   const validate = () => {
      let validationErrors: {
         name?: string,
         phone?: string,
         email?: string,
         enquiry?: string
      } = {};
      const isBlank = x => !x || !x.trim();
      if (isBlank(name)) {
         validationErrors.name = "Please specify your name";
      }

      if (isBlank(phone) && isBlank(email)) {
         validationErrors.phone = "Please specify your phone number or an e-mail address";
         validationErrors.email = validationErrors.phone;
      }

      if (isBlank(enquiry)) {
         validationErrors.enquiry = "Please tell me what you'd like to know";
      }

      return (Object.keys(validationErrors).length && validationErrors) || null;
   };

   return {
      name, setName,
      phone, setPhone,
      email, setEmail,
      enquiry, setEnquiry,
      inProgress,
      invalid,
      result: {message: resultMsg, css: resultCss},
      setRecaptcha,
      sendEnquiry
   }
}

export default function EnquiryForm() {
   const enquiryForm = useEnquiryForm();

   const invalidCss = field => invalidMsg(field) ? "input-invalid" : ""

   const invalidMsg = field => enquiryForm.invalid?.[field];
   

   return <div className="enquiry-form">
      <div className="form-data">
         <div className="form-row">
            <div>Name</div>
            <div><input type="text"
                        value={enquiryForm.name}
                        onChange={evt => enquiryForm.setName(evt.target.value)}
                        className={invalidCss('name')}/></div>
            <div className={"form-row-error invalid-msg"}>{invalidMsg('name')}</div>
         </div>
         <div className="form-row">
            <div>E-mail Address</div>
            <div><input type="text"
                        value={enquiryForm.email}
                        onChange={evt => enquiryForm.setEmail(evt.target.value)}
                        className={invalidCss('email')}/></div>
            <div className={"form-row-error invalid-msg"}>{invalidMsg('email')}</div>
         </div>
         <div className="form-row">
            <div>Phone Number</div>
            <div><input type="text"
                        value={enquiryForm.phone}
                        onChange={evt => enquiryForm.setPhone(evt.target.value)}
                        className={invalidCss('phone')}/></div>
            <div className={"form-row-error invalid-msg"}>{invalidMsg('phone')}</div>
         </div>
         <div className="form-row">
            <div>Details About Your Query</div>
            <div><textarea value={enquiryForm.enquiry}
                           onChange={evt => enquiryForm.setEnquiry(evt.target.value)}
                           className={invalidCss("enquiry")}/></div>
            <div className={"form-row-error invalid-msg"}>{invalidMsg('enquiry')}</div>
         </div>
         <GoogleRecaptcha name="enquiryForm" recaptchaId="recaptcha.dmc.enquiry.client-key" callback={enquiryForm.setRecaptcha}/>
         {!enquiryForm.inProgress && <ActionLinkGroup>
            <ActionLink action={enquiryForm.sendEnquiry}>Send <FontAwesomeIcon icon={faPaperPlane}/></ActionLink>
         </ActionLinkGroup>}
         {enquiryForm.result.message && <div className={enquiryForm.result.css}>
            {enquiryForm.result.message}
         </div>}
      </div>
   </div>
}