import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ContactInfo() {
   return <div className="contact-info">
      <div>
         <p>
            Call us if you have any questions, would like more information or to book a free
            consultation.
            Alternatively, please provide your details in the form and Dr. Mary will get back to you.
         </p>
         <h1>
            <div className="phone-number">Call 07498 994 388</div>
         </h1>
         <p>
            Follow us on our social media pages so you can keep up to date with our special offers and
            competitions.
         </p>
      </div>
      <div>
         <div className="contact-social-media">
            <a href="https://www.facebook.com/drmarysclinic" className="action-link">
               <FontAwesomeIcon icon={faFacebook}/> &nbsp;Facebook</a>
            <a href="https://www.instagram.com/drmarysclinic" className="action-link">
               <FontAwesomeIcon icon={faInstagram}/> &nbsp;Instagram</a>
         </div>
      </div>
   </div>

}