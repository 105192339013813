import {useState, useEffect} from "react";
import AmogeServer from "@unclejust/amoge-client/src/utils/AmogeServer";
import * as dayjs from "dayjs";
import {HeadlineConfig} from "./HeadlineEditor";

export type HeadlineViewProps = {
   preview?: boolean
   title?: string,
   subTitle?: string,
   footNote?: string
}

export default function HeadlineView(props: HeadlineViewProps) {
   const isVisible = (response?: HeadlineConfig, preview?: boolean) => {
      const todayjs = d => d && dayjs(d, ["YYYY-MM-DD", "DD-MMM-YYYY"]);
      let startDayjs = todayjs(response?.startDate);
      let endDayjs = todayjs(response?.endDate);
      let nowDayjs = dayjs();
      let startOK = !startDayjs || !nowDayjs.isBefore(startDayjs);
      let endOK = !endDayjs || nowDayjs.isBefore(endDayjs);
      return (response?.title || response?.subTitle) && (preview || (startOK && endOK));
   }

   const [title, setTitle] = useState("");
   const [subTitle, setSubTitle] = useState("");
   const [footNote, setFootNote] = useState("");
   const [visible, setVisible] = useState(isVisible(undefined, props.preview));

   const loadHeadline = function () {
      AmogeServer<HeadlineConfig>("/api/headline", null, false).then(response => {
         setVisible(isVisible(response));
         setTitle(response.title);
         setSubTitle(response.subTitle);
         setFootNote(response.footNote)
      })
   }

   useEffect(() => {
      loadHeadline();
   }, []);

   return <>
      {visible && <div className="headline-view">
         <div className="title">
            {title}
            {footNote && <span className="asterisk">*</span>}
         </div>
         <div className="sub-title">
            {subTitle}
         </div>
         {footNote && <div className="foot-note">
            <span className="asterisk">*</span>{footNote}
         </div>}
      </div>}
   </>
}