export default function TestimonialsList() {
   return <>
      <div className="testimonial" style={{opacity: "1"}}>
         <div>
            "...As a needle phobic person I was amazed as it didn't even hurt. I'm so happy with my
            forehead
            and have been getting so many compliments. I feel so much more confident as well..."
         </div>
      </div>
      <div className="testimonial">
         <div>
            "I highly recommend Mary!! I'm really pleased with everything she's done so far. In fact I'm
            going back for more!! Can't wait! Book now!!"
         </div>
      </div>
      <div className="testimonial">
         <div>
            "You are soooooo fabulous Mary can't wait to come back for some more Botox and fillers
            wouldn't
            go anywhere else"
         </div>
      </div>
      <div className="testimonial">
         <div>
            "Loved the forehead Botox. No more lines"
         </div>
      </div>
   </>
}